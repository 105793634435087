// Returns date in YYYY-MM-DD format. If no date is provided, use current date;
export const truncateISOString = (dateStr) => dateStr.split('T')[0];

// Returns date in ISO format. If no date is provided, use current date;
export const getISODate = (givenDate) => {
	let date = givenDate || new Date();
	return date.toISOString();
};

// Given date string in "2023-12-09T00:00:00Z" format, return "Sat Dec 09 2023"
export const getPrettyDate = (givenDate, skipWeekday) => {
	let targetDate = new Date(givenDate);

	const dateStr = targetDate.toLocaleDateString(undefined, {
		...(skipWeekday ? {} : { weekday: 'short' }),
		day: 'numeric',
		month: 'numeric',
		year: '2-digit'
	});

	return dateStr.replace(',', '');
};

// Cast to strings for comparison
export const idCompare = (id1, id2) => `${id1}` === `${id2}`;

// Get client full name from client object
export const getName = (client) =>
	client.last_name == '' || client.last_name == null
		? client.first_name
		: `${client.first_name} ${client.last_name}`;

// Return pretty USD price from Stripe's unit_amount_decimal field
export const getPrettyPrice = (unitPrice) =>
	unitPrice ? `$${unitPrice.slice(0, -2)}.${unitPrice.slice(-2)}` : '';

// Helper for updating paginated sessions object in cache
export const updatePaginatedSessionsCache = (prev, updatedSession, sessionId) => {
	const newPages = (prev?.pages || []).map((pageObj) => ({
		...pageObj,
		results: (pageObj.results || []).map((sessionObj) => {
			if (idCompare(sessionObj.id, sessionId)) {
				return updatedSession;
			}
			return sessionObj;
		})
	}));
	return {
		...prev,
		pages: newPages
	};
};

// Helper for updating both/either the paginated sessions cache or single session cache depending on existance
export const sessionCacheUpdate = (queryClient, updatedSession, clientId, sessionId) => {
	const existingSessionsData = queryClient.getQueryData(['sessions', `${clientId}`]);
	// if there is no previous sessions data, just leave blank so sessions will be fetched
	if (existingSessionsData) {
		queryClient.setQueryData(['sessions', `${clientId}`], (prev) =>
			updatePaginatedSessionsCache(prev, updatedSession, sessionId)
		);
	}

	// update individual session query (if it exists)
	const queryKey = ['session', `${clientId}`, `${sessionId}`];
	const existingSessionData = queryClient.getQueryData(queryKey);
	if (existingSessionData) {
		queryClient.setQueryData(queryKey, () => updatedSession);
	}
};
